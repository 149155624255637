import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/font/google/target.css?{\"path\":\"src/global/fonts.ts\",\"import\":\"Lato\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/font/google/target.css?{\"path\":\"src/global/fonts.ts\",\"import\":\"Cinzel\",\"arguments\":[{\"weight\":\"500\",\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-cinzel\"}],\"variableName\":\"cinzel\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_global/CookieConsent/_Content.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/global/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/CookieConsent/CookieConsent.module.scss");
